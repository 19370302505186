import { RequestStatus } from '../../types/request';

// export type AssessmentDefinition = {
//     id: number;                         
//     uuid: string;                       
//     name: string;                       
//     // assessment_type: AssessmentType;    
//     // assessment_version: AssessmentVersion; 
//   }


export interface AssessmentCreation {
    name: string;
    start_date: string;
    end_date: string;
    target_responses: number;
    status: 'D' | 'IP' | 'RR' | 'C';
    send_email_reminders: boolean;
    account_id: string;
    assessment_definition_id: number;
    assessment_group: number;
    is_child_assessment: boolean;
}

import { Moment } from 'moment';

export interface UpsertAssessmentPayload {
    id?: number;
    name: string;
    is_deleted?: boolean;
    start_date: string | null | Moment;
    end_date: string | null | Moment;
    target_responses: number;
    status?: AssessmentStatus;
    send_email_reminders?: boolean;
    assessment_definition_id: number | string;
    account_id: string;
    recipient_list?: { notify_email: string }[];
    temporary_recipient_list?: { notify_email: string }[];
}

export enum AssessmentStatus {
    DRAFT = 'D',
    IN_PROGRESS = 'IP',
    RESULTS_REVIEW = 'RR',
    CLOSED = 'C',
    DELETE = 'DELETE'
}


// Used to populate list of AssessmentTypes on Create and Edit Assessment
export interface AssessmentDefinitionResponse {
    id: number;
    name: string;
    assessment_type: string;
    version: string;
    created_by: string
}

export interface PublicGetAssessmentErrorResponse {
    error: string;
}

//Used to populate the card detials in AssessmentList
export interface AssessmentListDetail {
    id: string;
    uuid: string;
    name: string;
    start_date: string;
    end_date: string;
    target_responses: number;
    status: AssessmentStatus;
    readable_status: string;
    send_email_reminders: boolean;
    assessment_group_id: number;
    account: AccountInfo;
    assessment_definition: AssessmentListDefinition;
    is_child_assessment: boolean;
}

export interface AssessmentListDefinition {
    id: number;
    name: string;
    assessment_type: AssessmentType;
}

export interface User {
    id: string;
    email: string;
    fullname: string;
}

//Used to populate EnquiryForm
export interface PublicAssessmentDetailsReducer {
    assessment_details: PublicAssessmentOverviewDetails | null;
    status: RequestStatus;
}

export interface PublicAssessmentOverviewDetails {
    id: number;
    uuid: string;
    name: string;
    is_deleted: boolean;
    start_date: string | null;
    end_date: string | null;
    target_responses: number;
    status: AssessmentStatus;
    readable_status?: string;
    send_email_reminders: boolean;
    account: PublicAccountInfo;
    assessment_definition: AssessmentDefinition;
    assessment_group: number;
    is_child_assessment: boolean;
    child_assessment_id: string | null;
}

export interface PublicAccountInfo {
    id: string;
    name: string;
    city: string;
    state: string;
}

// Used for AssessmentDetails After Clicking on a Card in the AssessmentList
export interface AssessmentDetailsReducer {
    assessment_details: AssessmentOverviewDetails | null;
    status: RequestStatus;
}

export interface AssessmentDefinitionsReducer {
    assessment_definitions: AssessmentDefinition[] | null;
    status: RequestStatus;
}

export interface AssessmentCreationReducer {
    assessment_details: AssessmentDetails | null;
    status: RequestStatus;
}

export interface AssessmentAssessorScoreReducer {
    status: RequestStatus;
    assessmentAssessorScoreResponse: UpdateAssessorScoreData | null;
}

export interface AssessmentOverviewDetails {
    id: number;
    uuid: string;
    name: string;
    is_deleted: boolean;
    start_date: string | null;
    end_date: string | null;
    target_responses: number;
    response_count: number;
    status: AssessmentStatus;
    readable_status?: string;
    send_email_reminders: boolean;
    account: AccountInfo;
    assessment_recipients: Recipient[];
    assessment_definition: AssessmentDefinition;
    category_analysis_notes: CategoryAnalysisNote[];
    assessment_notes: AssessmentNote[];
    assessment_group: AssessmentGroup;
    is_child_assessment: boolean;
    assessment_scores: AssessmentScore[];
    created_by_id: string;
    created_at: string | null | Moment;
    last_updated_by_id: string;
    last_updated_at: string | null | Moment;
    recipient_list: string[];
    child_assessment?: AssessmentOverviewDetails | null;
}


export interface AccountInfo {
    id: string;
    name: string;
    website: string;
    city: string;
    state: string;
    account_executives: AccountExecutive[];
}

export interface Recipient {
    id: number;
    name: string;
    email: string;
    assessment_id: number;
}

export interface AccountExecutive {
    id: string;
    user_id: string;
    role: string;
    email: string;
    fullname: string;
}

export interface AssessmentDefinition {
    id: number;
    uuid: string;
    name: string;
    is_deleted: boolean;
    assessment_type: AssessmentType;
    assessment_version: AssessmentVersion;
    question_categories: AssessmentQuestionCategory[];
}

interface AssessmentType {
    id: number;
    uuid: string;
    name: string;
    is_deleted: boolean;
}

interface AssessmentVersion {
    id: number;
    uuid: string;
    summary: string;
    version: string;
    is_deleted: boolean;
}

export interface AssessmentQuestionCategory {
    id: number;
    name: string;
    description: string;
    assessment_questions: AssessmentQuestion[];
}

export interface CategoryAnalysisNote {
    id: number;
    challenges_threats: string[];
    root_causes: string[];
    strengths: string[];
    key_priorities: string[];
    development_opportunities: string[];
    implementation_details_and_timing: string[];
    possible_offerings: string[];
    go_forward_plan: string;
    assessment_id: number;
    question_category_id: number;
}

export interface CategoryAnalysisNotePayload {
    assessment_id: string;
    question_category_id: number;
    strengths?: string[];
    strengths_string?: string;
    challenges_threats?: string[];
    challenges_threats_string?: string;
    root_causes?: string[];
    root_causes_string?: string;
    possible_offerings?: string[];
    possible_offerings_string?: string;
    go_forward_plan: string;

    //These properties are moving to the Notes Tab
    // key_priorities?: string[];
    // key_priorities_string?: string;
    // development_opportunities?: string[];
    // development_opportunities_string?: string;
    // implementation_details_and_timing?: string[];
    // implementation_details_and_timing_string?: string;
}

export interface AssessmentQuestion {
    id: number;
    text: string;
    display_order: number;
    is_deleted: boolean;
    question_definition: QuestionDefinition;
    question_category_id: number;
}

interface QuestionDefinition {
    id: number;
    name: string;
    is_scorable: boolean;
    is_required: boolean;
    question_type: QuestionType;
    is_published: boolean;
    is_deleted: boolean;
    possible_answers?: PossibleAnswer[];
}

export enum QuestionType {
    TEXT = 'T',
    NUMERIC = 'N',
    NUMERIC_RANGE = 'NR',
    MULTIPLE_CHOICE = 'MC',
    MULTIPLE_SELECT = 'MS'
}

export enum MultipleChoiceType {
    REGULAR_MC = "R",
    LIKERT = "L",
    BINARY = "B"
}

export enum UnitOfMeasureType {
    PERCENT = "P",
    DOLLAR = "DLR",
    MINUTE = "MIN",
    HOUR = "HR",
    DAY = "DAY",
    WEEK = "WK",
    MONTH = "M",
    YEAR = "YR",
    EMPLOYEES = "EM",
    CUSTOMER = "CUS"
}


export interface PossibleAnswer {
    id: number;
    text: string | null;
    score: string;
    range: string | null;
    display_order: number | null;
}

export interface AssessmentNotesReducer {
    assessment_notes: AssessmentNote | null;
    status: RequestStatus;
}

export interface AssessmentNote {
    id: number;
    text?: string | null;
    assessment_id: number;
    created_by_id: string;
    created_at: string | null | Moment;
    last_updated_by_id: string;
    last_updated_at: string | null | Moment;
    development_opportunities?: string | null;
    implementation_details_and_timings?: string | null;
    key_priorities?: string | null;
}

export interface AssessmentNotesPayload {
    id: number;
    text: string;
    assessment_id: string;
    development_opportunities: string;
    implementation_details_and_timings: string;
    key_priorities: string;
}

interface AssessmentGroup {
    id: number;
    uuid: string;
    name: string;
    account_id: string;
}

export interface AssessmentScore {
    assessment_question_id: number;
    answer_count: number;
    average_score: number;
    assessor_score: number;
}

export interface UpdateAssessorScoreData {
    assessment_question_id: number;
    score: number | string | null;
    assessment_id: string;
}

export interface QuestionCategoryResult {
    name: string;
    id?: number;
    number_of_questions: number;
    max_cumulative_score: number | string;
    cumulative_average_score: number | string;
}

export interface MultipleChoiceScoreLineChartAnswerData {
    questions_and_answers: ScorableQuestionAndAnswer[];
}

export interface ScorableQuestionAndAnswer {
    question_id: number;
    question_text: string;
    question_type: QuestionType;
    multiple_choice_type: MultipleChoiceType;
    response_unit_of_measure: UnitOfMeasureType;
    question_lowest_score: number | string;
    question_highest_score: number | string;
    average_response_score: number;
}

//   Used for the View Detailing Category Scores Breakdown

export interface ScoresByCategoryReducer {
    assessment_category_details: ScoresByCategoryAssessmentDetails | null;
    status: RequestStatus;
}

export interface ScoresByCategoryAssessmentDetails {
    id: number;
    uuid: string;
    name: string;
    is_deleted: boolean;
    start_date: string | null | Moment;
    end_date: string | null | Moment;
    account: AccountInfo;
    scores_by_category: CategoryScoreDetails[];
}

export interface CategoryScoreDetails {
    assessment_id: number;
    assessment_uuid: string;
    category_id: number;
    category_name: string;
    category_display_order: number;
    question_count: number;
    scorable_answer_count: number;
    total_response_count: number;
    average_category_score: number;
    category_max_possible_score: number | string;
    average_category_assessor_score: number;
    category_analysis_notes: SimplifiedCategoryAnalysisNotes;
    questions: CategoryScoreQuestionDetails[];
}

export interface SimplifiedCategoryAnalysisNotes {
    challenges_threats: string[];
    root_causes: string[];
    strengths: string[];
    key_priorities: string[];
    development_opportunities: string[];
    implementation_details_and_timing: string[];
    possible_offerings: string[];
    go_forward_plan: string;
}

export interface CategoryScoreQuestionDetails {
    question_id: number;
    question_text: string;
    total_response_count: number;
    display_order: number;
    is_scorable: boolean;
    question_type: QuestionType;
    multiple_choice_type: MultipleChoiceType | null;
    response_unit_of_measure: UnitOfMeasureType;
    lowest_answer_score_value: number;
    highest_answer_score_value: number;
    assessor_score: number;
    scorable_answer_count: number;
    average_score: number;
    fill_in_response_answer_count: number;
    average_numeric_response: number;
    multiple_choice_answer_distribution: MultipleChoiceAnswerDistributionDetails[];
    free_responses: string[];
}

export interface MultipleChoiceAnswerDistributionDetails {
    answer_id: number;
    answer_text: string;
    answer_response_count: number;
    answer_score: string;
    answer_display_order: number;
}




// export interface AssessmentQuestionAnswer {
//     id: number;
//     text: string;
//     value: number | string;
//     created_at: string;
//     updated_at: string;
// }


//DEPRECATED
// Used for the EnquiryForm 
export type AssessmentDetails = {
    id: number;
    uuid: string;
    name: string;
    start_date: string;
    end_date: string;
    target_responses: number;
    status: AssessmentStatuses;
    send_email_reminders: boolean;
    is_deleted: boolean;
    assessment_type: AssessmentType;
    account: Account;
    assessment_version: AssessmentVersion;
    questions: Question[];
};


//DEPRECATED
interface Account {
    id: string;
    name: string;
    website: string;
    city: string;
    state: string;
}

//DEPRECATED
interface AssessmentVersion {
    id: number;
    uuid: string;
    summary: string;
    version: string;
    is_deleted: boolean;
}

//DEPRECATED
export interface Question {
    id: number;
    text: string;
    display_order: number;
    is_deleted: boolean;
    question_definition: QuestionDefinition;
    question_category: QuestionCategory;
}

//DEPRECATED
interface QuestionCategory {
    id: number;
    is_deleted: boolean;
    name: string;
    display_order: number;
}

//DEPRECATED
type AssessmentStatuses = 'D' | 'IP' | 'RR' | 'C';
