export interface CustomAccountBranding {
  company_name: string;
  evaluation_survey_description: string;
  company_logo_primary: string;
  company_logo_secondary: string;
  favicon: string;
  browser_tab_title: string;
  evaluation_property_name_translation: string;
  primary_color: string;
  secondary_color: string;
  login_headline: string,
  login_paragraph: string,
  assessor_score: string,
  active_feature_flags: {
    [key: string]: boolean;
  }
}

export enum FeatureFlags {
  SCOIN = "is_scoin_customer_flag",
  DASHBOARD = "is_dashboard_customer_flag",
  LEGACY_DASHBOARD = "is_legacy_dashboard_view_flag",
  ASSESSOR_SCORE = "is_assessor_score_customer_flag",
  LEGACY_WEASYPRINT = "is_legacy_weasyprint_report_required_flag",
  LEGACY_V1_ENDPOINTS = "use_legacy_v1_endpoints_flag",
  EOC_INTAKE_FORM_LINK = "use_employer_of_choice_intake_form_flag",
}

export interface TenantInterface {
  tenant: string,
  domain: string,
  company_logo_primary: string,
  company_logo_secondary: string,
  favicon: string,
  company_name: string,
  primary_color: string,
  secondary_color: string,
  browser_tab_title: string,
  evaluation_property_name_translation: string,
  login_headline: string,
  login_paragraph: string,
  assessor_score: string,
  active_feature_flags: {
    [key: string]: boolean;
  }
  
}