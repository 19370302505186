import { Box, Grid, Paper, TextField } from '@mui/material';
import React from 'react';
import {
    Typography19px600,
    Typography12px400,
    CommonTypography,
} from '../../../../../styles/Global.styles';
import { SaveButton } from '../../../../Accounts/CreateClient/CreateClient.styles';
import { store, useAppSelector } from '../../../../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { AssessmentOverviewDetails, QuestionCategoryResult, ScoresByCategoryAssessmentDetails } from '../../../type';
import BarChart from './BarChart';
import DistributionGraph from './DistributionGraph';
import ChallengesAndOpportunitiesComponent from './ChallengesAndOpportunities';
import { clearAssessmentDetails } from '../../../reducers/getAssessmentDetails'

const AssessmentAnalysis = ({ assessmentDetail, assessmentCategoryScoreDetails }: { assessmentDetail: AssessmentOverviewDetails, assessmentCategoryScoreDetails: ScoresByCategoryAssessmentDetails }) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const challengesAndOfferings = assessmentCategoryScoreDetails.scores_by_category.map((category) => {
        return (
            <ChallengesAndOpportunitiesComponent
                key={category.category_id.toString()}
                title={category.category_name}
                challengesThreats={category.category_analysis_notes.challenges_threats || []}
                possibleOfferings={category.category_analysis_notes.possible_offerings || []}
            />
        );
    });

    //Calculate Score Stats for the entire Assessment
    function calculateCombinedMaxScoresWithAverage(assessmentCategoryScoreDetails: ScoresByCategoryAssessmentDetails): QuestionCategoryResult {
        let totalQuestions = 0;
        let totalMaxCumulativeScore = 0;
        let totalCumulativeAverageScore = 0;

        assessmentCategoryScoreDetails.scores_by_category.forEach(category => {
            totalQuestions += category.question_count;
            totalMaxCumulativeScore += Number(category.category_max_possible_score);
            totalCumulativeAverageScore += category.average_category_score;
        });

        // Return the combined result
        return {
            name: "Total Survey Score",
            number_of_questions: totalQuestions,
            max_cumulative_score: totalMaxCumulativeScore,
            cumulative_average_score: totalCumulativeAverageScore,
        };
    }

    function calculateMaxScoresWithAverage(assessmentCategoryScoreDetails: ScoresByCategoryAssessmentDetails): QuestionCategoryResult[] {
        const results: QuestionCategoryResult[] = [];

        assessmentCategoryScoreDetails.scores_by_category.forEach(category => {
            results.push({
                name: category.category_name,
                id: category.category_id,
                number_of_questions: category.question_count,
                max_cumulative_score: Number(category.category_max_possible_score),
                cumulative_average_score: category.average_category_score
            });
        });

        return results;
    }

    const cumulativeBarChart = React.useMemo(() => {
        // Iterate through the results from calculateMaxScoresWithAverage
        const questionCategoryResult = calculateCombinedMaxScoresWithAverage(assessmentCategoryScoreDetails);

        return (
            <Grid
                item
                xs={12}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '1em 0', gap: '1em' }}
                key={questionCategoryResult.name}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5em' }}>
                    <div style={{
                        color: '#6A6A6A',
                        fontFamily: 'Inter',
                        fontSize: '1em',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '27.233px',
                        letterSpacing: '-0.424px',
                    }}>
                        {questionCategoryResult.name}
                    </div>
                    {/* <div style={{
                        display: 'flex',
                        padding: '3.026px 15.13px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1em',
                        borderRadius: '6.052px',
                        background: '#EFF7FB',
                    }}>
                        <div style={{
                            color: '#6A6A6A',
                            fontFamily: 'Inter',
                            fontSize: '0.8em',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '27.233px',
                            letterSpacing: '-0.303px',
                        }}>
                            {questionCategoryResult.number_of_questions} Questions
                        </div>
                    </div> */}
                </div>

                <BarChart
                    cumulative_average_score={Number(questionCategoryResult.cumulative_average_score)}
                    max_cumulative_score={Number(questionCategoryResult.max_cumulative_score)}
                    bar_height='51px'
                    bar_width='650px'
                    bar_color='#053d58'
                />
            </Grid>
        );
    }, [assessmentCategoryScoreDetails]);

    const barCharts = React.useMemo(() => {
        // Iterate through the results from calculateMaxScoresWithAverage
        const questionCategoryResults = calculateMaxScoresWithAverage(assessmentCategoryScoreDetails);

        return questionCategoryResults.map((questionCategoryResult, index) => (
            <Grid
                item
                key={questionCategoryResult.name}
                sm={12}
            >
                {index !== 0 && <hr style={{
                    border: 'none', borderTop: '2px solid #2FA9E3', width: '90%', marginLeft: '5%'
                }}
                />}
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '1em 5% 1em 5%' }}
                >
                    {/* Label portion of each category row  */}
                    <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                        <div style={{
                            color: '#6A6A6A',
                            fontFamily: 'Inter',
                            fontSize: '1em',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '27.233px',
                            letterSpacing: '-0.424px',
                        }}>
                            {questionCategoryResult.name}
                        </div>
                        {/* <div style={{
                            display: 'flex',
                            padding: '3.026px 15.13px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1em',
                            borderRadius: '6.052px',
                            background: '#EFF7FB',
                        }}>
                            <div style={{
                                color: '#6A6A6A',
                                fontFamily: 'Inter',
                                fontSize: '0.8em',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '27.233px',
                                letterSpacing: '-0.303px',
                            }}>
                                {questionCategoryResult.number_of_questions} Questions
                            </div>
                        </div> */}
                    </div>

                    {/* BarChart portion of each category row  */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                    }}
                    >
                        <BarChart
                            cumulative_average_score={Number(questionCategoryResult.cumulative_average_score)}
                            max_cumulative_score={Number(questionCategoryResult.max_cumulative_score)}
                        />
                    </div>
                </div>
            </Grid >
        ));
    }, [assessmentCategoryScoreDetails]);

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '25px',
                    right: '25px',
                }}
            >
                <SaveButton
                    sx={{
                        paddingX: 2,
                        fontSize: '18px'
                    }}
                    onClick={() => {
                        store.dispatch(clearAssessmentDetails());
                        navigate(
                            `/evaluation-assessment-detail/${id || ''}/evaluation-assessment-analysis-score`,
                        );
                    }}
                >
                    {'Report Builder'}
                </SaveButton>
            </Box>

            <Box sx={{ marginTop: '2em' }}>
                <Grid
                    item
                    sx={{
                        borderRadius: '10px',
                        background: '#FFF',
                        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
                        minHeight: '200px',
                        margin: '2em 0',
                        padding: '20px',
                        width: '100%',
                    }}
                >
                    <Typography19px600>{'Current State Summary'}</Typography19px600>
                    <Typography12px400
                        sx={{
                            color: '#B5B5C3',
                            marginBottom: '20px'
                        }}
                    >
                        {'Overview of the total survey score'}
                    </Typography12px400>
                    <Grid container justifyContent="center" alignItems='center'>
                        {cumulativeBarChart}
                    </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="center" alignContent="space-around">
                    {challengesAndOfferings}
                </Grid>

                <Grid
                    item
                    marginBottom={'70px'}
                    sx={{
                        borderRadius: '10px',
                        background: '#FFF',
                        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
                        minHeight: '200px',
                        margin: '10px 0',
                        padding: '20px',
                        width: '100%',
                    }}
                >
                    <Typography19px600>{'Survey Results by Category'}</Typography19px600>
                    <Typography12px400
                        sx={{
                            color: '#B5B5C3',
                            marginBottom: '20px'
                        }}
                    >
                        {'Brief overview of results by category'}
                    </Typography12px400>
                    <Grid container justifyContent="center" alignItems="center">
                        {barCharts}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

export default AssessmentAnalysis;
