import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    CircularProgress,
    Paper,
    Typography,
    Grid,
    Container,
    Tab,
    Tabs,
    collapseClasses,
} from '@mui/material';
import LikertScale from './../../../components/LikertScale/LikertScale';
import MultipleChoiceSelect from '../../../components/MultipleChoiceSelect/MultipleChoiceSelect';
import { RootStoreType } from '../../../redux/store.type';
import useGetEvaluationQuestions from './../../Accounts/hooks/useGetEvaluationQuestions';
import useGetEvaluationName from '.././../Evaluations/hooks/useGetEvaluationName';
import { EvaluationNameResponse } from './../../Evaluations/reducers/getEvaluationName';
import { EvaluationQuestions } from './../../Accounts/types';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '.././../../styles/Global.styles';
import usePostEvalutaionSurvey from '../../Evaluations/hooks/usePostEvaluationSurvey';
import {
    CancelButton,
    SaveButton,
} from '.././../Accounts/CreateClient/CreateClient.styles';
import translation from '.././../../locales/en/translation.json';
import AssessmentNavBar from '../../../components/NavBar/AssessmentNavBar';
import { CustomAccountBranding } from './../../Branding/type';
import AssessmentConfirmationModal from './subComponents/AsssessmentConfirmationModal';
import { PublicAssessmentOverviewDetails, QuestionType, AssessmentQuestion } from '../../Assessments/type';
import usePostAssessmentEnquiryForm from '../hooks/usePostAssessmentEnquiryForm';
import { AssessmentEnquiryFormRequest, SingleQuestionResponse } from '../type';
import RowRadioButtonsMCGroup from './subComponents/RadioButtonsMCGroup';
import TextEntryInput from './subComponents/TextEntryInput';
import NumericEntryInput from './subComponents/NumericEntryInput';
import { toast } from 'react-toastify';

interface AssessmentFormProps {
    assessmentDetail: PublicAssessmentOverviewDetails;
    branding: CustomAccountBranding;
    setParentTab: (tabIndex: number) => void;
}

const AssessmentForm: React.FC<AssessmentFormProps> = ({ assessmentDetail, branding, setParentTab }) => {
    // const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [formData, setFormData] = useState<SingleQuestionResponse[]>([]);

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [areAllRequiredQuestionsAnswered, setAreAllRequiredQuestionsAnswered] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);

    const tabContainerRef = useRef<HTMLDivElement | null>(null);
    const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

    const addToRefs = (el: HTMLDivElement | null, index: number) => {
        if (el) {
            questionRefs.current[index] = el;
        }
    };


    // const questions: EvaluationQuestions[] = useSelector(
    //     (state: RootStoreType) => {
    //         return state.allClientReducer.getEvaluationQuestions.questions;
    //     },
    // );

    // const questionCategories = Array.from(
    //     new Set(questions.map((q) => q.evaluation_question_category_name)),
    // );

    const questionCategories: string[] = assessmentDetail?.assessment_definition?.question_categories?.map(
        (category) => category.name
    ) ?? [];

    const categorizedQuestions = assessmentDetail.assessment_definition.question_categories

    const handleFormChange = (questionId: number, selectedAnswerId?: number, answerText?: string) => {
        setFormData(prevData => {
            const existingAnswerIndex = prevData.findIndex(answer => answer.assessment_question_id === questionId);
            if (existingAnswerIndex >= 0) {
                const updatedAnswers = [...prevData];
                updatedAnswers[existingAnswerIndex] = {
                    ...updatedAnswers[existingAnswerIndex],
                    selected_answer_id: selectedAnswerId,
                    answer: answerText,
                };
                return updatedAnswers;
            } else {
                return [
                    ...prevData,
                    { assessment_question_id: questionId, selected_answer_id: selectedAnswerId, answer: answerText }
                ];
            }
        });
    };

    const renderInput = (question: AssessmentQuestion) => {
        switch (question.question_definition.question_type) {
            case QuestionType.MULTIPLE_CHOICE:
                return (
                    <RowRadioButtonsMCGroup
                        question={question}
                        options={question.question_definition.possible_answers || []}
                        formData={formData}
                        onChange={handleFormChange}
                    />
                );
            case QuestionType.NUMERIC_RANGE:
            case QuestionType.NUMERIC:
                return (
                    <NumericEntryInput
                        question={question}
                        formData={formData}
                        onChange={handleFormChange}
                    />
                );
            case QuestionType.TEXT:
                return (
                    <TextEntryInput
                        question={question}
                        formData={formData}
                        onChange={handleFormChange}
                    />
                );
            default:
                return null;
        }
    };

    const { postSurvey } = usePostAssessmentEnquiryForm();

    const scrollToFirstUnanswered = () => {
        const firstUnansweredRequiredIndex = categorizedQuestions[currentTab].assessment_questions.findIndex(
            (question) =>
                question.question_definition.is_required &&
                !formData.some(answer => answer.assessment_question_id === question.id)
        );


        if (questionRefs.current[firstUnansweredRequiredIndex]) {
            questionRefs.current[firstUnansweredRequiredIndex]?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const onSubmit = async () => {
        const requiredQuestions = categorizedQuestions[currentTab].assessment_questions.filter(
            question => question.question_definition.is_required
        );

        const areAllRequiredAnswered = requiredQuestions.every(question =>
            formData.some(answer => answer.assessment_question_id === question.id)
        );

        if (areAllRequiredAnswered) {
            const formattedData: AssessmentEnquiryFormRequest = { answers: formData };
            try {
                // Await the result of the postSurvey call
                const isSuccessful = await postSurvey(assessmentDetail.uuid, formattedData);

                if (isSuccessful) {
                    setParentTab(2);
                } else {
                    toast.error("Submission failed. Please try again.");
                }
            } catch (error) {
                toast.error("An error occurred during form submission. Please try again later.");
            }
        } else {
            // Handle case where not all required questions are answered
            setHasSubmitted(true);
            scrollToFirstUnanswered();
        }
    };

    useEffect(() => {
        document.body.style.backgroundColor = '#f9f9f9';
        if (hasSubmitted) {
            const requiredQuestions = categorizedQuestions[currentTab].assessment_questions.filter(
                question => question.question_definition.is_required
            );

            const areAllRequiredAnswered = requiredQuestions.every(question =>
                formData.some(answer => answer.assessment_question_id === question.id)
            );

            setAreAllRequiredQuestionsAnswered(areAllRequiredAnswered);

            if (!areAllRequiredAnswered) {
                scrollToFirstUnanswered();
            }
        }
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, [formData, categorizedQuestions, hasSubmitted]);

    useEffect(() => {
        if (tabContainerRef.current) {
            tabContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [currentTab]);

    const handleNext = () => {
        setHasSubmitted(true);

        const requiredQuestions = categorizedQuestions[currentTab].assessment_questions.filter(
            question => question.question_definition.is_required
        );

        const areAllRequiredAnswered = requiredQuestions.every(question =>
            formData.some(answer => answer.assessment_question_id === question.id)
        );

        if (
            areAllRequiredAnswered &&
            currentTab < questionCategories.length - 1
        ) {
            setCurrentTab(currentTab + 1);
            setHasSubmitted(false);
        } else {
            setAreAllRequiredQuestionsAnswered(areAllRequiredAnswered);
            scrollToFirstUnanswered();
        }
    };

    const handleBack = () => {
        if (currentTab > 0) {
            setCurrentTab(currentTab - 1);
        }
    };

    // const skipCategory = () => {
    //     if (currentTab < questionCategories.length - 1) {
    //         setCurrentTab(currentTab + 1);
    //     }
    // };

    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const handleSkipCategory = () => {
    //     if (currentTab === questionCategories.length - 1) {
    //         setIsModalOpen(true);
    //     } else {
    //         skipCategory(); // Function to move to the next category
    //         setHasSubmitted(false);
    //     }
    // };

    // const handleConfirmSubmitFromModal = () => {
    //     // const filteredFormData = Object.fromEntries(
    //     //     Object.entries(formData).filter(([_, value]) => value !== 'N/A'),
    //     // );
    //     // postSurvey(id!, filteredFormData);
    //     const formattedData: AssessmentEnquiryFormRequest = { answers: formData };
    //     postSurvey(assessmentDetail.uuid, formattedData);
    //     setIsModalOpen(false);
    //     setParentTab(2);
    // }

    if (!assessmentDetail) {
        return (
            <Typography
                variant="h6"
                sx={{
                    margin: '40px auto',
                    color: '#9095a1',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    textAlign: 'center',
                }}
            >
                {"Evaluation doesn't exist"}
            </Typography>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ backgroundColor: 'white' }}>
            {categorizedQuestions.length > 0 ? (
                <div ref={tabContainerRef}>
                    {/* <AssessmentNavBar branding={branding}></AssessmentNavBar> */}
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.SyntheticEvent, newValue: number) => {
                            // Prevent changing the tab by clicking on it
                            event.preventDefault();
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{ marginTop: '5%' }}
                    >
                        {questionCategories.map((category, index) => (
                            // <Tab key={index} label={category} sx={
                            <Tab
                                key={index}
                                label={`Section ${index + 1}`}
                                sx={{
                                    backgroundColor: currentTab === index ? '#f9f9f9' : 'white',
                                    color: '#053D58', // Optional: change text color based on selection
                                    borderRadius: '4px', // Optional: adds rounded corners for better look
                                    marginRight: '10px', // Optional: space between tabs
                                    cursor: 'default',
                                }}
                            />
                        ))}
                    </Tabs>
                    <Grid container>
                        <Grid item sx={{ backgroundColor: 'white' }} sm={9}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: 'Inter',
                                    backgroundColor: 'white',
                                    paddingLeft: '5%',
                                    paddingTop: '3%',
                                    paddingBottom: '2%',
                                }}
                            >
                                {currentTab + 1}. {questionCategories[currentTab]} -{' '}
                                {categorizedQuestions[currentTab].assessment_questions.length} Questions
                            </Typography>
                        </Grid>
                        {/* TODO: Add Skip Category Logic Before Moving over Customers Currently on V1 */}
                        {/* <Grid
                            item
                            sx={{ backgroundColor: 'white', display: "flex", alignItems: 'center', justifyContent: "end", paddingRight: "2.5%" }}
                            sm={3}
                        >
                            <CancelButton
                                onClick={handleSkipCategory}
                                sx={{

                                    display: "flex",
                                    padding: "7px 24px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "1empx",

                                    backgroundColor: 'white',
                                    color: branding.primary_color,
                                    border: '2px solid rgba(0, 0, 0, 0.1)',
                                    fontFamily: "Inter",
                                    // fontSize: "24px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                }}
                            >
                                <span style={{

                                    display: "flex",
                                    paddingRight: "7px",
                                    alignItems: "center",
                                    justifyContent: "center",

                                    backgroundColor: 'white',
                                    color: branding.primary_color,
                                    maxWidth: "33px",
                                    maxHeight: "22px",
                                }}>
                                    <svg
                                        width="33"
                                        height="22"
                                        viewBox="0 0 33 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.5373 9.34121C16.7142 10.1339 16.7142 11.8661 15.5373 12.6588L3.11732 21.0246C1.78887 21.9194 -7.00131e-08 20.9675 0 19.3658L7.31363e-07 2.63419C8.01376e-07 1.03247 1.78887 0.0805801 3.11732 0.975394L15.5373 9.34121Z" fill={branding.primary_color || "#053D58"} />
                                        <path d="M31.5373 9.34121C32.7142 10.1339 32.7142 11.8661 31.5373 12.6588L19.1173 21.0246C17.7889 21.9194 16 20.9675 16 19.3658V2.63419C16 1.03247 17.7889 0.0805801 19.1173 0.975394L31.5373 9.34121Z" fill={branding.primary_color || "#053D58"} />
                                    </svg>
                                </span>
                                Skip Category
                            </CancelButton>
                            <AssessmentConfirmationModal
                                open={isModalOpen}
                                handleClose={() => setIsModalOpen(false)}
                                handleConfirm={handleConfirmSubmitFromModal}
                            />
                        </Grid> */}
                    </Grid>
                    <Grid
                        sx={{
                            backgroundColor: 'white'
                        }}>
                        <hr
                            style={{ width: '95%', margin: 'auto', backgroundColor: 'white' }}
                        />
                    </Grid>
                    {/* To Be Added in V2 */}
                    {/* <Typography
            variant="body2"
            sx={{
              color: '#6A6A6A',
              fontFamily: 'Inter',
              backgroundColor: 'white',
              paddingLeft: '5%',
              paddingTop: '2%',
            }}
          >
            Some category summary text here
          </Typography> */}

                    <Typography
                        variant="body2"
                        sx={{
                            color: '#6A6A6A',
                            fontFamily: 'Inter',
                            backgroundColor: 'white',
                            paddingLeft: '5%',
                            // fontSize: "32px",
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '150%',
                            paddingTop: '2%',
                        }}
                    >
                        Let&apos;s Start!
                    </Typography>

                    <form>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                backgroundColor: 'white',
                                paddingLeft: '5%',
                                paddingTop: '2%',
                                marginTop: '0px',
                                width: 'auto',
                            }}
                        >
                            {categorizedQuestions[currentTab]?.assessment_questions?.map((question, i) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={question.id}
                                    sx={{ paddingLeft: '0px' }}
                                >
                                    <Paper
                                        ref={(el) => addToRefs(el, i)}
                                        sx={{
                                            margin: '0rem',
                                            marginBottom: '2.5rem',
                                            border: '0px',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        <Box>
                                            <Box
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-start',
                                                    position: 'relative',
                                                }}
                                            >
                                                <span style={{ width: '30px' }}>{` ${i + 1}. `}</span>
                                                <Typography variant="body1" sx={{ flex: 1 }}>
                                                    {question.text}
                                                </Typography>
                                            </Box>
                                            {renderInput(question)}
                                            {
                                                !formData.some(answer => answer.assessment_question_id === question.id) &&
                                                question.question_definition.is_required &&
                                                hasSubmitted && (
                                                    <ErrorMessage
                                                        sx={{
                                                            paddingLeft: '25px',
                                                            fontSize: '16px',
                                                            color: 'red',
                                                        }}
                                                    >
                                                        {translation.this_field_is_required}
                                                    </ErrorMessage>
                                                )}
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </form>
                </div>
            ) : (
                <Typography
                    variant="h4"
                    sx={{
                        margin: '40px auto',
                        color: '#9095a1',
                        fontFamily: 'Inter',
                        fontWeight: 900,
                        textAlign: 'center',
                    }}
                >
                    Either you have submitted the response once or this evaluation does
                    not contain any questions
                </Typography>
            )
            }

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '5%',
                    marginBottom: '8%',
                }}
            >
                <SaveButton onClick={handleBack} disabled={currentTab === 0}>
                    Back
                </SaveButton>

                {hasSubmitted && !areAllRequiredQuestionsAnswered && (
                    <Typography color="red" sx={{ textAlign: 'right' }}>
                        Ensure all questions have been answered before continuing.
                    </Typography>
                )}
                {currentTab === questionCategories.length - 1 ? (
                    <SaveButton onClick={onSubmit}>Submit</SaveButton>
                ) : (
                    <SaveButton onClick={handleNext}>Next</SaveButton>
                )}
            </Box>
        </Container >
    );
};

export default AssessmentForm;
