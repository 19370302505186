import { useAppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { postSupplier } from '../reducers/postSupplier';
import { Supplier } from '../type';
import { toast } from 'react-toastify';

interface createSupplierProps {
  error: unknown;
  loading: boolean;
  createSupplier: (createClientRequest: Supplier) => Promise<Supplier | null>;
}

const useCreateSupplier = (): createSupplierProps => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const createSupplier = async (requestPayload: Supplier): Promise<Supplier | null> => {
    try {
      setLoading(true);
      const resultAction = await dispatch(postSupplier(requestPayload));
      if (postSupplier.fulfilled.match(resultAction)) {
        toast.success('Supplier created successfully');
        setLoading(false);
        return resultAction.payload as Supplier;
      } else if (postSupplier.rejected.match(resultAction)) {
        const error = resultAction.payload as string;
        toast.error(`Failed to create supplier`);
        setError(error);
      }
    } catch (error: unknown) {
      let errorMessage = 'Failed to create supplier';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setError(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
    return null;
  };

  return { createSupplier, loading, error };
};

export default useCreateSupplier;
