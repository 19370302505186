import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { PostEvaluationLinkPayload } from '../type';
import { toast } from 'react-toastify';
import { RequestStatus } from '../../../types/request';

const initialState = {
  status: RequestStatus.Init,
};

const postEvaluationLinkSlice = createSlice({
  name: '/postEvaluationLink',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postEvaluationLink.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postEvaluationLink.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      toast.success(action.payload.message);
    });
    builder.addCase(postEvaluationLink.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      const errorResponses = (action.payload as AxiosError).response?.data as {
        key: string;
      };
      Object.values(errorResponses).forEach((value) => {
        toast.error(value);
      });
    });
  },
});

export const postEvaluationLink = createAsyncThunk<
  { message: string },
  PostEvaluationLinkPayload
>('/postSurveyLink', async (requestPayload, thunkAPI) => {
  try {
    const response: AxiosResponse<{ message: string }> = await postLink(
      requestPayload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const postLink = async (
  requestPayload: PostEvaluationLinkPayload,
): Promise<AxiosResponse<{ message: string }>> => {
  return await axiosInstance.post(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `${EndPoints.POST_EVALUATION_LINK}`,
    requestPayload,
  );
};

export default postEvaluationLinkSlice.reducer;
