import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export interface evaluationNotesRequest {
  params: { evaluation_id: string; };
  payload: EvaluationNotesPayload;
}

export interface EvaluationNotesPayload {
  evaluation_notes: string;
}
const initialState = {
  status: RequestStatus.Init,
};

const putEvaluationResultNotesSlice = createSlice({
  name: 'evaluation/putEvaluationNotes',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(putEvaluationNotesThunk.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(putEvaluationNotesThunk.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      toast.success('Notes Updated Successfully');
    });
    builder.addCase(
      putEvaluationNotesThunk.rejected,
      (state, action) => {
        state.status = RequestStatus.Error;
        const errorString = (action.payload as AxiosError).response?.data as {
          error: string;
        };
        toast.error(
          errorString.error || "Notes Couldn't Update",
        );
      },
    );
  },
});

export const putEvaluationNotesThunk = createAsyncThunk<
  string,
  evaluationNotesRequest
>(
  'updateEvaluationNotes',
  async (
    evaluationNotesRequest: evaluationNotesRequest,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<string> = await axiosInstance.put(
        `${EndPoints.EVALUATION_NOTES}`,
        evaluationNotesRequest.payload,
        { params: evaluationNotesRequest.params },
      );
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default putEvaluationResultNotesSlice.reducer;
