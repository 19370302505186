import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  Link,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import AssessmentCard from './AssessmentCard';
import { useNavigate } from 'react-router-dom';
import { SaveButton } from '../../../Accounts/CreateClient/CreateClient.styles';
import useGetAssessments from '../../hooks/useGetAssessments';
import ConfirmationBox from '../../../../components/ConfirmationBox/ConfirmBox';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../../../redux/store.type';
import { AssessmentListDetail } from '../../type';
import useDeleteAssessment from '../../hooks/useDeleteAssessment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';

interface AssessmentListProps {
  displayEmployerOfChoiceIntakeFormLink: boolean | null;
}

const AssessmentList: React.FC<AssessmentListProps> = ({ displayEmployerOfChoiceIntakeFormLink }) => {
  const [assessments, setAssessments] = useState<AssessmentListDetail[]>([]); // Store all assessments
  const [filteredAssessments, setFilteredAssessments] = useState<AssessmentListDetail[]>([]); // Store filtered assessments
  const [searchText, setSearchText] = useState('');
  const [statusType, setStatusType] = useState('');
  const [employerOfChoiceIntakeFormLink, setEmployerOfChoiceIntkaeFormLink] = useState<string | null>(null);
  const [visibleItems, setVisibleItems] = useState(10); // How many items to display at first
  const [loadingMore, setLoadingMore] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  //TODO: This needs to be updated to be V2 Delete Assessment when it is made
  const { loading: deleteLoading, success: deleteSuccess, deleteAssessmentById } = useDeleteAssessment();
  const [openConfirmBox, SetOpenConfirmBox] = useState(false);
  const [deleteEvaluationId, setDeleteEvaluationId] = useState('');
  const { error, loading, getAllAssessments: getAllAssessments } = useGetAssessments();

  const freshAssessments = useSelector(
    (state: RootStoreType) =>
      state.assessmentReducers.getAssessmentsReducer
        .assessments as AssessmentListDetail[],
  );

  useEffect(() => {
    if (freshAssessments) {
      setAssessments(freshAssessments); // Set the assessments from the Redux store
      setFilteredAssessments(freshAssessments); // Optionally update the filtered assessments
    }
  }, [freshAssessments]);

  useEffect(() => {
    getAllAssessments();
  }, []);

  useEffect(() => {
    if (displayEmployerOfChoiceIntakeFormLink) {
      const baseUrl = window.location.origin;
      setEmployerOfChoiceIntkaeFormLink(`${baseUrl}/intake-form`);
    }
  }, [displayEmployerOfChoiceIntakeFormLink]);

  useEffect(() => {
    if (!deleteLoading && deleteSuccess) {
      getAllAssessments();
    }
  }, [deleteLoading, deleteSuccess]);

  function handleDelete(id: string) {
    SetOpenConfirmBox(true);
    setDeleteEvaluationId(id);
  }

  useEffect(() => {
    const filtered = assessments.filter((assessment) => {
      const matchesSearch =
        assessment.name.toLowerCase().includes(searchText.toLowerCase()) ||
        assessment.account.name
          .toLowerCase()
          .includes(searchText.toLowerCase());

      const matchingExecs = assessment.account.account_executives.some((exec) =>
        exec.fullname ? exec.fullname.toLowerCase().includes(searchText.toLowerCase()) : false,
      );

      const combinedMatches = matchesSearch || matchingExecs;

      const matchesStatus =
        statusType === '' ||
        (Array.isArray(statusType)
          ? statusType.includes(assessment.readable_status)
          : assessment.readable_status === statusType);

      return combinedMatches && matchesStatus;
    });
    setFilteredAssessments(filtered);
    setVisibleItems(8);
  }, [searchText, statusType, assessments]);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100 && !loadingMore) {
        loadMoreItems();
      }
    }
  };

  const loadMoreItems = () => {
    if (visibleItems < filteredAssessments.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setVisibleItems((prev) => prev + 8);
        setLoadingMore(false);
      }, 1000); //should we fake "load" on infinite scrolly boy
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [filteredAssessments, visibleItems, loadingMore]);

  return (
    <Box
      sx={{ maxWidth: '1540px', overflowY: 'auto', height: '90vh' }}
      ref={scrollRef}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        {/* Search and Filter Section */}
        <Grid
          item
          sm={4}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center', // Center align the inputs to match other components
            gap: 1,
          }}
        >
          <Grid item sm={6}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  left: '0%', // Shift label to center
                  // transform: 'translateX(-50%)', // Center the label horizontally
                  top: '-20%', // Shift label vertically to center
                  // transform: 'translate(-150%, -50%)', // Center both vertically and horizontally
                },
                '& .MuiInputLabel-shrink': {
                  transform: 'translate(0, -50%) scale(0.75)', // Keep the standard shrinking behavior
                  left: '0',
                  top: '0',
                },
                '& .MuiOutlinedInput-root': {
                  height: '40px', // Match the set height
                },
              }}
              InputLabelProps={{
                shrink: false, // Prevents the label from shrinking on focus or with input
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <Select
              value={statusType}
              onChange={(e) => setStatusType(e.target.value)}
              displayEmpty
              fullWidth
              sx={{
                height: '40px', // Match the fixed height of the TextField
                '& .MuiOutlinedInput-root': {
                  height: '100%', // Match the set height
                },
              }}
            >
              <MenuItem value="">All Statuses</MenuItem>
              <MenuItem value="Draft">Draft</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Results Review">Results Review</MenuItem>
              <MenuItem value="Closed">Closed</MenuItem>
            </Select>
          </Grid>
        </Grid>

        {/* Employer of Choice Intake Form Link */}
        {displayEmployerOfChoiceIntakeFormLink && employerOfChoiceIntakeFormLink && (
          <Grid
            item
            sm={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            {/* Label Text */}
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: '14px',
                color: '#2E3645',
                marginBottom: '4px',
              }}
            >
              Employer of Choice Intake Form:
            </Typography>

            {/* Link Box with Integrated Copy Button */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '40px', // Set fixed height to match other elements
                width: '350px',
                backgroundColor: '#F7F7F7',
                border: '1px solid #E0E0E0',
                borderRadius: '4px',
                overflow: 'hidden',
                boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Link Section */}
              <Box
                sx={{
                  padding: '8px 12px',
                  flex: 1,
                  color: '#2E3645',
                  overflow: 'hidden',
                }}
              >
                <Link
                  href={employerOfChoiceIntakeFormLink}
                  sx={{
                    color: '#2E3645',
                    textDecoration: 'none',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 400,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  }}
                >
                  {employerOfChoiceIntakeFormLink}
                </Link>
              </Box>

              {/* Copy Button Integrated Inside the Link Box */}
              <Tooltip title="Copy link">
                <IconButton
                  onClick={() => navigator.clipboard.writeText(employerOfChoiceIntakeFormLink)}
                  size="small"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#2E3645',
                    backgroundColor: '#FFFFFF',
                    borderLeft: '1px solid #E0E0E0',
                    padding: '8px 12px',
                    fontSize: '14px',
                    borderRadius: 0,
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      fontWeight: 600,
                      ml: 0.5,
                    }}
                  >
                    Copy
                  </Typography>
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}

        {/* Create Assessment Button */}
        <Grid
          item
          sm={2}
          sx={{
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SaveButton
            onClick={() => navigate(`/create-evaluation-assessment`)}
            sx={{
              height: '40px', // Match height of other elements
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Inter',
            }}
          >
            Create Assessment
          </SaveButton>
        </Grid>
      </Grid>

      {
        filteredAssessments.length === 0 ? (
          <Box sx={{ textAlign: 'center', marginTop: '5%' }}>
            <Typography variant="h6" color="textSecondary">
              No assessments currently created.
            </Typography>
          </Box>
        ) : (
          filteredAssessments.slice(0, visibleItems).map((assessment) => (
            <Box
              key={assessment.id}
              sx={{ paddingBottom: '1.5%', cursor: 'pointer' }}
              onClick={() =>
                navigate(`/evaluation-assessment-detail/${assessment.uuid}`)
              }
            >
              <AssessmentCard
                assessmentDetails={assessment}
                deleteAssessment={handleDelete}
              />
            </Box>
          ))
        )
      }

      {
        openConfirmBox && (
          <ConfirmationBox
            doOpen={openConfirmBox}
            deleteRecord={() => {
              deleteAssessmentById(deleteEvaluationId);
            }}
            doClose={() => {
              SetOpenConfirmBox(false);
            }}
            confirmationMessage="Respondents will not be able to submit responses after it has been deleted."
          />
        )
      }

      {
        loadingMore && (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}
          >
            <CircularProgress />
          </Box>
        )
      }
    </Box >
  );
};

export default AssessmentList;
