import React from 'react';
import { Box, Grid } from '@mui/material';
import { AccountSettingsUser } from '../../../../Accounts/AccountSettings/AccountSettings';
import { AccountInformation } from '../../../reducers/getAccount';
import { AssessmentOverviewDetails, AssessmentStatus } from '../../../type';
import GeneralAssessmentInfoCard from './GeneralAssessmentInfoCard';
import AssessmentResponsesGraphCard from './AssessmentResponsesGraphCard';
import AssessmentDistributionCard from './AssessmentDistributionCard';
import AssessmentReport from './AssessmentReport';
import AssessmentDirectRecipientCard from './AssessmentDirectRecipientCard';

const AssessmentDetailsView = (prop: {
  assessmentDetail: AssessmentOverviewDetails; // Update prop
  accountInformation: AccountInformation;
  accountUserData: AccountSettingsUser[];
}) => {
  const { assessmentDetail, accountInformation, accountUserData } = prop;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>

        {assessmentDetail && (
          <Grid container spacing={4} sx={{ display: 'flex', justifyContent: "center" }}>

            <Grid item xs={12} md={assessmentDetail.child_assessment ? 8 : 6}>
              <GeneralAssessmentInfoCard
                accountInfo={accountInformation && accountInformation}
                info={assessmentDetail} // Update prop
                accountUserData={accountUserData}
                content={assessmentDetail.child_assessment ? 'start' : 'end'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>

                <Grid item sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginLeft: '2em',
                  }}
                >
                  < Box
                    sx={{
                      fontSize: '18px',
                      fontWeight: 600,
                      fontFamily: 'Inter'
                      // marginLeft: '2em',
                      // marginBottom: '0em',
                      // paddingBottom: '0em'
                    }}
                  >
                    {assessmentDetail.assessment_definition.assessment_type.name}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      marginLeft: '10px',
                      backgroundColor: '#F7F9FB',
                      padding: '30px 20px',
                      borderRadius: '16px',
                    }}
                  >
                    <AssessmentResponsesGraphCard info={assessmentDetail} />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  {(assessmentDetail.status !== AssessmentStatus.RESULTS_REVIEW &&
                    assessmentDetail.status !== AssessmentStatus.CLOSED) && (
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#F7F9FB',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <AssessmentDistributionCard info={assessmentDetail} />
                        <AssessmentDirectRecipientCard info={assessmentDetail} />
                      </Box>
                    )}
                </Grid>

                {assessmentDetail &&
                  (assessmentDetail.status == AssessmentStatus.RESULTS_REVIEW ||
                    assessmentDetail.status == AssessmentStatus.CLOSED) && (
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#F7F9FB',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <AssessmentReport info={assessmentDetail} />
                      </Box>
                    </Grid>
                  )}

              </Grid>
            </Grid>

            {assessmentDetail.child_assessment && (
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      marginLeft: '2em',
                    }}
                  >
                    < Box
                      sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        fontFamily: 'Inter'
                        // marginLeft: '2em',
                        // marginBottom: '0em',
                        // paddingBottom: '0em'
                      }}
                    >
                      {assessmentDetail.child_assessment.assessment_definition.assessment_type.name}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box
                      sx={{
                        marginLeft: '10px',
                        backgroundColor: '#F7F9FB',
                        padding: '30px 20px',
                        borderRadius: '16px',
                      }}
                    >
                      <AssessmentResponsesGraphCard info={assessmentDetail.child_assessment} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {(assessmentDetail.child_assessment.status !== AssessmentStatus.RESULTS_REVIEW &&
                      assessmentDetail.child_assessment.status !== AssessmentStatus.CLOSED) && (
                        <Box
                          sx={{
                            marginLeft: '10px',
                            backgroundColor: '#F7F9FB',
                            padding: '30px 20px',
                            borderRadius: '16px',
                          }}
                        >
                          <AssessmentDistributionCard info={assessmentDetail.child_assessment} />
                          <AssessmentDirectRecipientCard info={assessmentDetail.child_assessment} />
                        </Box>
                      )}
                  </Grid>

                  {assessmentDetail.child_assessment &&
                    (assessmentDetail.child_assessment.status == AssessmentStatus.RESULTS_REVIEW ||
                      assessmentDetail.child_assessment.status == AssessmentStatus.CLOSED) && (
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            marginLeft: '10px',
                            backgroundColor: '#F7F9FB',
                            padding: '30px 20px',
                            borderRadius: '16px',
                          }}
                        >
                          <AssessmentReport info={assessmentDetail.child_assessment} />
                        </Box>
                      </Grid>
                    )}

                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid >
  );
};

export default AssessmentDetailsView;
