import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export interface EvaluationStatusPayload {
  evaluation_id: string;
  to_state: string;
}

const postEvaluationStatusSlice = createSlice({
  name: 'evaluation/postEvaluationStatus',
  initialState: { status: RequestStatus.Init },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateEvaluationStatus.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(updateEvaluationStatus.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      toast.success(action.payload.success);
    });
    builder.addCase(updateEvaluationStatus.rejected, (state) => {
      state.status = RequestStatus.Error;
      toast.error('Unable to update the status');
    });
  },
});

export const updateEvaluationStatus = createAsyncThunk<
  { success: string },
  EvaluationStatusPayload
>(
  'editEvaluationStatus',
  async (evaluationPayload: EvaluationStatusPayload, { rejectWithValue }) => {
    try {
      const result: AxiosResponse<{ success: string }> =
        await axiosInstance.post(
          EndPoints.POST_CHANGE_EVALUATION_STATUS,
          evaluationPayload,
        );
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export default postEvaluationStatusSlice.reducer;
