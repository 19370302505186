/* eslint-disable indent */
import React, { useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    CircularProgress,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import CategoryAnalysisNotesField from '../../../CategoryAnalysis/components/CategoryAnalysisNotesField';
import { AssessmentNotesPayload } from '../../../type';
import { assessmentAnalysisNotesFields } from '../../../Constants/AssessmentAnalysisNotes';
import { Typography12px400, Typography10px400, CustomButton } from '../../../../../styles/Global.styles';

const placeholderText = {
    color: '#B5B5C3',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '5px',
};

const innerText = {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '5px',
};

const AssessmentAnalysisNotes = ({ initialAssessmentNotes, onNotesSubmit }) => {
    const [assessmentNotes, setAssessmentNotes] = useState(initialAssessmentNotes);
    const resultAnalysisForm = useForm<FieldValues & AssessmentNotesPayload>({});
    const { register, control, setValue, handleSubmit, formState: { errors } } = resultAnalysisForm;

    React.useEffect(() => {
        setValue('text', assessmentNotes?.text as string || '');
        setValue('key_priorities', assessmentNotes?.key_priorities as string || '');
        setValue('development_opportunities', assessmentNotes?.development_opportunities as string || '');
        setValue('implementation_details_and_timings', assessmentNotes?.implementation_details_and_timings as string || '');
    }, [assessmentNotes, setValue]);

    const handleFormSubmit = (data: AssessmentNotesPayload) => {
        onNotesSubmit(data);

        // Update local state with the submitted values to prevent form reset
        setAssessmentNotes({
            ...assessmentNotes,
            ...data,
        });
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit as SubmitHandler<FieldValues & AssessmentNotesPayload>)}>
            <Grid
                container
                sx={{
                    paddingTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Grid item sm={12}>
                    {assessmentAnalysisNotesFields
                        .map((fieldConfig, index) => (
                            <CategoryAnalysisNotesField
                                key={index}
                                control={control}
                                register={register}
                                errors={errors}
                                {...fieldConfig}
                                minLength={fieldConfig.minLength ?? 0}
                            />
                        ))}

                    <Box
                        sx={{
                            margin: '20px 10px',
                            marginTop: '10px'
                        }}>
                        <Typography12px400
                            sx={{
                                color: '#181C32',
                                paddingBottom: '5px',
                            }}
                        >
                            Notes
                        </Typography12px400>

                        <Typography10px400
                            sx={{
                                color: '#B5B5C3',
                            }}
                        >
                            Add any additional notes to be included in the report.
                        </Typography10px400>

                        <TextField
                            placeholder='Add any additional notes to be included in the report.'
                            id="outlined-basic"
                            sx={{
                                width: '90%',
                                marginTop: '10px',
                            }}
                            multiline={true}
                            rows={20}
                            InputLabelProps={{
                                style: placeholderText,
                            }}
                            InputProps={{
                                style: innerText,
                            }}
                            {...register('text')}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    sm={12}
                    sx={{
                        margin: '30px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <CustomButton
                        type="submit"
                        sx={{
                            color: '#fff',
                            minWidth: '100px',
                        }}
                    >
                        Save
                    </CustomButton>
                </Grid>
            </Grid>
        </form>
    );
};

export default AssessmentAnalysisNotes;