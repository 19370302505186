import React, {
  ChangeEvent,
  CSSProperties,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import regionalManufacturerAssociations from './RegionalManufacturersAssociations';
import MenuHelper from './Helpers';

interface IntakeFormRegionalManufacturersAssocProps {
  formData: { regionalManufacturersAssoc: string[] };
  onFormChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
      | SelectChangeEvent<string[]>,
  ) => void;
}

const IntakeFormRegionalManufacturersAssoc: FunctionComponent<
  IntakeFormRegionalManufacturersAssocProps
> = ({ formData, onFormChange }) => {
  const [selectedAssociation, setSelectedAssociation] = useState<string>('');

  const handleAssociationChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedAssociation(event.target.value as string);
    onFormChange(event);
  };

  return (
    <Container sx={{ marginTop: '3%' }}>
      <Typography variant="h4" color="primary">
        Is your company a member of a Regional Manufacturers Association?
      </Typography>
      <Box sx={{ marginTop: '3%' }}>
        <Typography variant="body1">
          Please choose the association your company is affiliated with from the
          drop-down list.
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: '3%' }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select one or more options</InputLabel>
            <Select
              label="Select one or more options"
              multiple
              value={formData.regionalManufacturersAssoc}
              onChange={handleAssociationChange}
              name="regionalManufacturersAssoc"
              renderValue={(selected) => selected
                .map((id) => {
                  const association = regionalManufacturerAssociations.find(x=>x.id.toString() == id);
                  return association!.name as string;
                })
                .join(', ')}
              MenuProps={MenuHelper}
            >
              {regionalManufacturerAssociations.map((rmAssociation, index) => (
                <MenuItem
                  key={rmAssociation.id}
                  value={rmAssociation.id.toString()}
                  selected={formData.regionalManufacturersAssoc.includes(
                    rmAssociation.id.toString(),
                  )}
                  sx={{
                    backgroundColor:
                      formData.regionalManufacturersAssoc.includes(
                        rmAssociation.id.toString(),
                      )
                        ? 'lightblue'
                        : 'inherit',
                    fontWeight: formData.regionalManufacturersAssoc.includes(
                      rmAssociation.id.toString(),
                    )
                      ? 'bold'
                      : 'normal',
                  }}
                >
                  {rmAssociation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntakeFormRegionalManufacturersAssoc;
