import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Supplier } from '../type';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';
import { RequestStatus } from '../../../types/request';

const initialState = {
  status: RequestStatus.Init,
};

export const putSupplier = createAsyncThunk<string, Supplier>(
  `scoin/putUpdateSupplier`,
  async (requestPayload: Supplier, { rejectWithValue }) => {
    try {
      const endpoint = `${EndPoints.SCOIN_SUPPLIER}`;
      const response: AxiosResponse<string> = await axiosInstance.put(
        endpoint,
        requestPayload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const putSupplierSlice = createSlice({
  name: 'putUpdateSupplier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(putSupplier.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(putSupplier.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      toast.success('Account Updated Successfully!');
    });
    builder.addCase(putSupplier.rejected, (state) => {
      state.status = RequestStatus.Error;
      toast.error("Supplier can't be updated");
    });
  },
});

export default putSupplierSlice.reducer;

