import React, { useEffect, useState } from 'react';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Grid,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import IntakeFormMethodology from './IntakeFormMethodology';
import IntakeFormDemographics from './IntakeFormDemographics';
import IntakeFormOverView from './IntakeFormOverView';
import { useBranding } from '../../modules/Branding/reducers/getBranding';
import usePostAccountAndContact from '../../modules/Accounts/hooks/usePostAccount';
import {
  PostAccountRequest,
  PostAccountResponse,
} from '../../modules/Accounts/reducers/postAccountAndContact';
import {
  AssessmentCreation,
  AssessmentDefinition,
  AssessmentDetails,
} from '../../modules/Assessments/type';
import { useAppSelector } from '../../redux/store';
import usePostAssessment from '../../modules/Assessments/hooks/usePostAssessment';
import usePostGroup from '../../modules/Assessments/hooks/usePostGroup';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useGetPublicAssessmentDefinitions from '../../modules/Assessments/hooks/useGetPublicAssessmentDefinitions';
import {
  PostGroupRequest,
  PostGroupResponse,
} from '../../modules/Assessments/reducers/postGroup';
import { group } from 'console';
import IntakeFormFailureModal from './IntakeFormFailureModal';
import { mapToAssessmentCreation } from './Helpers';

const IntakeFormMain = () => {
  const branding = useBranding();
  const [loading, setLoading] = useState(false); // Add loading state
  const postAccountAndContact = usePostAccountAndContact();
  const getAssessmentDefinitions = useGetPublicAssessmentDefinitions();
  const postGroup = usePostGroup();
  const postAssessment = usePostAssessment();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[] | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [createdAssessmentId, setCreatedAssessmentId] = useState<string>('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    companyName: '',
    companySize: '',
    industryType: [],
    regionalManufacturersAssoc: [],
    address2: '',
    phoneNumber: '',
    city: '',
    state: '',
    zipCode: '',
    county: '',
    participationAgreement: '',
  });


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleFormChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
      | SelectChangeEvent<string[]>,
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const isFormComplete = Object.values(formData).every((value) =>
    Array.isArray(value) ? value.length > 0 : value.trim() !== '',
  );

  const assessmentDefinitions: AssessmentDefinition[] | null = useAppSelector(
    (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.assessmentReducers.getAssessmentDefinitionsReducer
        .assessment_definitions;
    },
  );

  const postedAssessment: AssessmentDetails | null = useAppSelector((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.assessmentReducers.getCreatedAssessmentReducer
      .assessment_details;
  });

  const onNextClick = async () => {
    if (isFormComplete) {
      setLoading(true);
      //Create Contact and Account
      const account: PostAccountRequest = createAccountRequest(formData);
      const accountAndContacts =
        await postAccountAndContact.postAccountAndContacts(account);
      if (!accountAndContacts || !accountAndContacts.data) {
        const errorJsonString =
          accountAndContacts.response.data.error.toString();
        const matches = [...errorJsonString.matchAll(/string='(.*?)'/g)].map(
          (match) => match[1] as string,
        ) as string[];
        setErrorMessage(matches);
        setIsModalOpen(true);
        return;
      }
      const accountData = accountAndContacts.data as PostAccountResponse;

      // Create group for assessments with account info
      const today = new Date();
      const year = today.getFullYear();
      const groupRequest: PostGroupRequest = {
        name: `${year} ${accountData.name}`,
        account_id: accountData.id,
      };
      const assessmentGroupResponse = await postGroup.postGroups(groupRequest);
      const assessmentGroup = assessmentGroupResponse as PostGroupResponse;

      if (!accountAndContacts || !accountAndContacts.data) {
        const errorJsonString =
          accountAndContacts.response.data.error.toString();
        const matches = [...errorJsonString.matchAll(/string='(.*?)'/g)].map(
          (match) => match[1] as string,
        ) as string[];
        setErrorMessage(matches);
        setIsModalOpen(true);
        return;
      }

      // if (!assessmentDefinitions) {
      //   const errors = ["No assessment definitions available. Contact # to continue with assessment creation."]
      //   setErrorMessage(errors)
      //   setIsModalOpen(true);
      //   return;
      // }

      const dateNow = today.toISOString().split('T')[0];

      const futureDate = new Date();
      futureDate.setDate(today.getDate() + 14);

      const dateNowPlus14Days = futureDate.toISOString().split('T')[0];

      const targetResponses = 5;

      // create assessments with newly created group and account data
      const employerOfChoiceSurveyDefinition = assessmentDefinitions!.find(
        (assessmentDef) =>
          assessmentDef.name.includes('Employer of Choice Survey'),
      );

      const employerOfChoiceSurvey = mapToAssessmentCreation(
        accountData.id,
        employerOfChoiceSurveyDefinition!.id,
        accountData.name + ' ' + employerOfChoiceSurveyDefinition!.name,
        dateNow,
        dateNowPlus14Days,
        targetResponses,
        assessmentGroup.id,
      );

      const employeeEngagementSurveyDefinition = assessmentDefinitions!.find(
        (assessmentDef) =>
          assessmentDef.name.includes('Employee Engagement Survey'),
      );

      const employeeEngagementSurvey = mapToAssessmentCreation(
        accountData.id,
        employeeEngagementSurveyDefinition!.id,
        accountData.name + ' ' + employeeEngagementSurveyDefinition!.name,
        dateNow,
        dateNowPlus14Days,
        targetResponses,
        assessmentGroup.id,
        true
      );

      const eocAssessment = await postAssessment.postAssessment(
        employerOfChoiceSurvey,
      );
      
      const eesAssessment = await postAssessment.postAssessment(
        employeeEngagementSurvey,
      );
      setCreatedAssessmentId(eocAssessment.uuid as string);
    }
  };

  useEffect(() => {
    if (createdAssessmentId) {
      navigate(`/evaluate/${createdAssessmentId}`);
    }
  }, [createdAssessmentId, navigate]);

  const handleRetry = () => {
    setIsModalOpen(false);
    navigate(`/intake-form`);
    setLoading(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(`/`);
  };

  return (
    <Container sx={{ maxWidth: '100%', minWidth: '100%' }}>
      <IntakeFormFailureModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onRetry={handleRetry}
        errorMessage={errorMessage}
      />
      {loading ||
      !branding ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <>
          <AppBar
            position="static"
            sx={{
              backgroundColor: 'white',
              boxShadow: 'none',
              flexDirection: 'row',
            }}
          >
            <Grid sx={{ width: '5%' }} item>
              <img
                style={{
                  width: '95%',
                  height: 'auto',
                  margin: '20px 0',
                  objectFit: 'contain',
                }}
                src="/employerimg.png"
                alt="EmployerLogo"
              />
            </Grid>
            <Grid sx={{ width: '85%', marginY: 'auto' }} item>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab
                  label="Introduction / Overview"
                  sx={{
                    borderBottom: tabIndex >= 0 ? '2px solid #3787bc' : 'none',
                  }}
                />
                <Tab
                  label="Methodology"
                  sx={{
                    borderBottom: tabIndex >= 1 ? '2px solid #3787bc' : 'none',
                  }}
                />
                <Tab
                  label="Demographic Information"
                  sx={{
                    borderBottom: tabIndex >= 2 ? '2px solid #3787bc' : 'none',
                  }}
                />
              </Tabs>
            </Grid>
          </AppBar>

          <Box mt={4}>
            {tabIndex === 0 && <IntakeFormOverView />}
            {tabIndex === 1 && <IntakeFormMethodology />}
            {tabIndex === 2 && (
              <IntakeFormDemographics
                formData={formData}
                onFormChange={handleFormChange}
                onNextClick={onNextClick}
                onStepChange={setTabIndex}
                branding={branding}
                assessmentDefinitions={assessmentDefinitions}
              />
            )}
          </Box>

          <Container>
            <Grid
              item
              sx={{ display: 'flex', marginTop: '3%' }}
              justifyContent={tabIndex == 0 ? 'flex-end' : 'space-between'}
            >
              {tabIndex != 2 && tabIndex != 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setTabIndex((prev) => prev - 1)}
                >
                  <ArrowBackIcon sx={{ marginRight: '8%' }} /> Back
                </Button>
              )}
              {tabIndex != 2 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setTabIndex((prev) => prev + 1)}
                >
                  Continue <ArrowForwardIcon sx={{ marginLeft: '8%' }} />
                </Button>
              )}
            </Grid>
          </Container>
        </>
      )}
    </Container>
  );
};

export default IntakeFormMain;

function createAccountRequest(formData: {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  companyName: string;
  companySize: string;
  industryType: never[];
  regionalManufacturersAssoc: never[];
  address2: string;
  phoneNumber: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  participationAgreement: string;
}): PostAccountRequest {
  return {
    name: formData.companyName,
    email: formData.email,
    website: null,
    address1: formData.address2,
    address2: null,
    country: null,
    city: formData.city,
    state: formData.state,
    zipcode: formData.zipCode,
    is_active: true,
    account_contacts: [
      {
        first_name: `${formData.firstName}`,
        last_name: `${formData.lastName}`,
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        phone: formData.phoneNumber,
        is_primary_contact: true,
        role: formData.jobTitle,
        created_at: new Date().toISOString(),
        created_by: null,
      },
    ],
    naics_codes: formData.industryType,
    rmAssociations: formData.regionalManufacturersAssoc,
    companySize: formData.companySize,
    county: formData.county,
  };
}
