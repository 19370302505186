import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Supplier } from '../type';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';
import { RequestStatus } from '../../../types/request';

interface PostSupplierState {
  status: RequestStatus;
  supplier?: Supplier;
}

const initialState: PostSupplierState = {
  status: RequestStatus.Init,
};

export const postSupplier = createAsyncThunk<Supplier, Supplier>(
  'scoin/postCreateSupplier',
  async (requestPayload: Supplier, { rejectWithValue }) => {
    try {
      const endpoint = `${EndPoints.SCOIN_SUPPLIER}`;
      const response: AxiosResponse<Supplier> = await axiosInstance.post(endpoint, requestPayload);
      return response.data;
    } catch (error) {
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (error && typeof error === 'object' && 'response' in error) {
        errorMessage = (error as any).response?.data ?? errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  },
);

const postSupplierSlice = createSlice({
  name: 'postCreateSupplier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postSupplier.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postSupplier.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.supplier = action.payload;
      toast.success('Supplier created successfully!');
    });
    builder.addCase(postSupplier.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      const error = action.payload as string;
      toast.error(`Supplier can't be created: ${error}`);
    });
  },
});

export default postSupplierSlice.reducer;

