import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { CreateEvaluationType } from '../type';
import { AppDispatch } from '../../../redux/store';
import { toast } from 'react-toastify';

const initialState = {
  status: RequestStatus.Init,
};

const postEvaluationSlice = createSlice({
  name: '/postEvaluation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postEvaluation.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postEvaluation.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      toast.success('Evaluation Created Successfully!');
    });
    builder.addCase(postEvaluation.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      const errorResponses = (action.payload as AxiosError).response?.data as {
        error: string;
      };

      toast.error(errorResponses.error);
    });
  },
});

export const postEvaluation = createAsyncThunk<
  string,
  CreateEvaluationType,
  { dispatch: AppDispatch }
>('/createEvaluation', async (evaluationPayload, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<string, void> = await createEvaluation(
      evaluationPayload,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createEvaluation = async (
  evaluationPayload: CreateEvaluationType,
): Promise<AxiosResponse<string>> => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return await axiosInstance.post(
    `${EndPoints.POST_EVALUATION}`,
    evaluationPayload,
  );
};

export default postEvaluationSlice.reducer;
