import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Tooltip,
    useTheme
} from '@mui/material';
import { AssessmentOverviewDetails, AssessmentStatus } from '../../../../Assessments/type';
import { usePostAssessmentLink } from '../../../hooks/usePostAssessmentLink';

const AssessmentDirectRecipientCard = ({ info }: { info: AssessmentOverviewDetails }) => {
    const { postAssessmentLink } = usePostAssessmentLink(); // Updated function for posting assessment link
    const theme = useTheme();

    return (
        // <h1>hello</h1>
        <Box
            sx={{
                marginTop: '40px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                }}
                variant="h5"
                component="h2"
                gutterBottom
            >
                Direct Recipient
            </Typography>

            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                            variant="h6"
                            component="h6"
                            gutterBottom
                        >
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                            }}
                        >
                            {info?.assessment_recipients.map((recipient, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Grid item xs={12} sm={8}>
                                            <Tooltip title={recipient.email} placement="top-start">
                                                <Typography
                                                    sx={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        minWidth: '205px',
                                                    }}
                                                    variant="body1"
                                                >
                                                    {recipient.email || ''}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            {info.status === AssessmentStatus.IN_PROGRESS && ( // Updated status check
                                                <Button
                                                    sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        textTransform: 'none',
                                                        borderRadius: '6px',
                                                        padding: '4px 12px',
                                                        fontSize: '14px',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            backgroundColor: theme.palette.primary.main,
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        postAssessmentLink({
                                                            recipient_ids: [recipient.id],
                                                            assessment_id: info.uuid,
                                                            email: recipient.email,
                                                        });
                                                    }}
                                                    variant="contained"
                                                >
                                                    Resend
                                                </Button>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default AssessmentDirectRecipientCard;
