import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { storeKey } from '../constants';
import { UpdateAccountPayload } from '../types';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';
import { RequestStatus } from '../../../types/request';

export interface AccountAndContactInitialState {
  status: RequestStatus;
  accountAndContactData: PostAccountRequest | null;
}

const initialState :AccountAndContactInitialState = {
  status: RequestStatus.Init,
  accountAndContactData: null,
};

const postAccountAndContactSlice = createSlice({
  name: 'postAccountAndContact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postAccountAndContact.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postAccountAndContact.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.accountAndContactData=action.payload;
      toast.success('Account Created Successfully!');
    });
    builder.addCase(postAccountAndContact.rejected, (state) => {
      state.status = RequestStatus.Error;
      toast.error("Account can't be created");
    });
  },
});


//first arg for create thunk is response I believe, need to create response contract
export const postAccountAndContact = createAsyncThunk<PostAccountResponse, PostAccountRequest>(
  `${storeKey}/postAccountAndContact`,
  async (postAccountPayload: PostAccountRequest, { rejectWithValue }) => {
    try {
      // Create FormData instance
      const formDataObj = new FormData();
      
      // account data
      formDataObj.append('name', postAccountPayload.name);
      formDataObj.append('email', postAccountPayload.email);
      // formDataObj.append('website', postAccountPayload.website);
      formDataObj.append('address1', postAccountPayload.address1);
      formDataObj.append('address2', postAccountPayload.address2 || ''); 
      formDataObj.append('country', postAccountPayload.country || '');
      formDataObj.append('city', postAccountPayload.city);
      formDataObj.append('state', postAccountPayload.state);
      formDataObj.append('zipcode', postAccountPayload.zipcode);
      formDataObj.append('is_active', String(postAccountPayload.is_active));
      formDataObj.append('company_size', postAccountPayload.companySize);
      formDataObj.append('county', postAccountPayload.county);
      formDataObj.append('naics_codes[]', JSON.stringify(postAccountPayload.naics_codes));
      formDataObj.append('regional_manufacturers_associations[]', JSON.stringify(postAccountPayload.rmAssociations));
      // postAccountPayload.naics_codes.forEach((code, index) => {
      //   formDataObj.append(`naics_codes[{index}]`, code);
      // });
      // postAccountPayload.rmAssociations.forEach((assoc, index) => {
      //   formDataObj.append(`regional_manufacturers_associations[{index}]`, assoc);
      // });

      // account contacts
      postAccountPayload.account_contacts.forEach((contact, index) => {
        formDataObj.append(`account_contacts[${index}].first_name`, contact.first_name);
        formDataObj.append(`account_contacts[${index}].last_name`, contact.last_name);
        formDataObj.append(`account_contacts[${index}].name`, contact.name);
        formDataObj.append(`account_contacts[${index}].email`, contact.email);
        formDataObj.append(`account_contacts[${index}].phone`, contact.phone);
        formDataObj.append(`account_contacts[${index}].is_primary_contact`, String(contact.is_primary_contact));
        formDataObj.append(`account_contacts[${index}].role`, contact.role);
        formDataObj.append(`account_contacts[${index}].created_at`, contact.created_at);
        formDataObj.append(`account_contacts[${index}].created_by`, contact.created_by || '');
      });
      const endpoint = `public/account`;
      const response: AxiosResponse<PostAccountResponse> = await axiosV2Instance.post(
        endpoint,
        formDataObj
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postAccountAndContactsReducer = postAccountAndContactSlice.reducer;

export type PostAccountContactRequest = {
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  phone: string;
  is_primary_contact: boolean;
  role: string;
  created_at: string; 
  created_by: string | null;
}

export type PostAccountRequest = {
  name: string;
  email: string;
  website: string | null;
  address1: string;
  address2: string | null;
  country: string | null;
  city: string;
  state: string;
  zipcode: string;
  is_active: boolean;
  account_contacts: PostAccountContactRequest[]; 
  naics_codes: string[];
  rmAssociations: string[];
  companySize: string
  county: string
}

export type PostAccountResponse = {
  id: string;
  name: string;
  email: string;
  website: string | null;
  address1: string;
  address2: string | null;
  country: string | null;
  city: string;
  state: string;
  zipcode: string;
  is_active: boolean;
  account_contacts: PostAccountContactResponse[]; 
  naics_codes: string[];
  rmAssociations: string[];
  companySize: string
  county: string
}

export type PostAccountContactResponse = {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  phone: string;
  is_primary_contact: boolean;
  role: string;
  created_at: string; 
  created_by: string | null;
}