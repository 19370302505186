import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface DeleteAssessmentState {
    status: RequestStatus;
    error: string | null;  // Error can be either a string or null
}

const initialState: DeleteAssessmentState = {
    status: RequestStatus.Init,
    error: null,
};

export const deleteAssessment = createAsyncThunk<
    void,  // No return value on success
    string,  // The assessment ID (string)
    { rejectValue: string }  // Custom reject type (error message)
>(
    'assessment/deleteAssessment',
    async (assessmentId, { rejectWithValue }) => {
        try {
            await axiosV2Instance.delete(`${EndPoints.ASSESSMENT}/${assessmentId}`);
        } catch (error: AxiosError | any) {
            const errorMessage = (error.response?.data?.message as string) || 'Failed to delete assessment';
            return rejectWithValue(errorMessage);  // Return a string as reject value
        }
    }
);

const deleteAssessmentSlice = createSlice({
    name: 'deleteAssessment',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteAssessment.pending, (state) => {
                state.status = RequestStatus.Loading;
                state.error = null;
            })
            .addCase(deleteAssessment.fulfilled, (state) => {
                state.status = RequestStatus.Done;
                state.error = null;
                toast.success('Successfully Deleted');
            })
            .addCase(deleteAssessment.rejected, (state, action) => {
                state.status = RequestStatus.Error;
                state.error = action.payload || 'Something went wrong';
                toast.error(state.error);
            });
    },
});

export default deleteAssessmentSlice.reducer;
