import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export const deleteEvaluationThunk = createAsyncThunk<string, string>(
  'deleteEvaluation',
  async (evaluation_id: string, { rejectWithValue }) => {
    try {
      const result: AxiosResponse<string> = await axiosInstance.delete(
        EndPoints.DELETE_EVALUATION,
        {
          data: { evaluation_id },
        },
      );
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const deleteEvaluationSlice = createSlice({
  name: 'delete-evaluation',
  initialState: { status: RequestStatus.Init },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(deleteEvaluationThunk.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(deleteEvaluationThunk.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      toast.success('Evaluation Deleted Successfully!');
    });
    builder.addCase(deleteEvaluationThunk.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const deleteEvaluationReducer = deleteEvaluationSlice.reducer;
