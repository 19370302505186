import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { EvaluationSurveyPayload } from '../type';
import { toast } from 'react-toastify';
import { RequestStatus } from '../../../types/request';

const initialState = {
  status: RequestStatus.Init,
};

const postEvaluationSurveySlice = createSlice({
  name: '/postEvaluationSurvey',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postEvaluationSurvey.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(postEvaluationSurvey.fulfilled, (state) => {
      state.status = RequestStatus.Done;
      toast.success('Evaluation Completed Successfully!');
    });
    builder.addCase(postEvaluationSurvey.rejected, (state, action) => {
      state.status = RequestStatus.Error;
      const errorResponses = (action.payload as AxiosError).response?.data as {
        key: string;
      };
      Object.values(errorResponses).forEach((value) => {
        toast.error(value);
      });
    });
  },
});

export const postEvaluationSurvey = createAsyncThunk<
  string,
  { id: string; requestPayload: EvaluationSurveyPayload }
>('/createEvaluationSurvey', async ({ id, requestPayload }, thunkAPI) => {
  try {
    const response: AxiosResponse<string> = await createEvaluationSurvey(
      requestPayload,
      id,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createEvaluationSurvey = async (
  requestPayload: EvaluationSurveyPayload,
  id: string,
): Promise<AxiosResponse<string>> => {
  return await axiosInstance.post(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `${EndPoints.POST_EVALUATION_SURVEY}` + id + '/',
    requestPayload,
  );
};

export default postEvaluationSurveySlice.reducer;
