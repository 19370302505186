import React, {
  ChangeEvent,
  CSSProperties,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import industryTypes from './IndustryTypes';
import MenuHelper from './Helpers';

interface IntakeFormIndustryTypeProps {
  formData: { industryType: string[] };
  onFormChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
      | SelectChangeEvent<string[]>,
  ) => void;
}

const IntakeFormIndustryType: FunctionComponent<
  IntakeFormIndustryTypeProps
> = ({ formData, onFormChange }) => {
  const [selectedIndustry, setSelectedIndustry] = useState<string[]>([]);

  const handleIndustryChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedIndustry(event.target.value as string[]);
    onFormChange(event);
  };

  return (
    <Container sx={{ marginTop: '3%' }}>
      <Typography variant="h4" color="primary">
        Industry Type
      </Typography>
      <Box sx={{ marginTop: '3%' }}>
        <Typography variant="body1">
          The North American Industry Classification System (NAICS) is a
          six-digit coding system that categorizes economic activity into 20
          industry sectors. The NAICS is used by federal statistical agencies to
          classify businesses for collecting, analyzing, and publishing
          statistical data about the U.S. economy.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '2%' }}>
          Please identify your industry code category from the drop-down list.
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: '3%' }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select one or more options</InputLabel>
            <Select
              label="Select one or more options"
              multiple
              value={formData.industryType}
              onChange={handleIndustryChange}
              name="industryType"
              renderValue={(selected) => selected
                .map((id) => {
                  const industry = industryTypes.find(x=> x.id.toString() == id);
                  return industry!.definition as string;
                })
                .join(', ')}
              MenuProps={MenuHelper}
            >
              {industryTypes.map((industry, index) => (
                <MenuItem
                  key={industry.id}
                  value={industry.id.toString()}
                  // selected={formData.industryType.includes(industry)}
                  sx={{
                    backgroundColor: formData.industryType.includes(industry.id.toString())
                      ? 'lightblue'
                      : 'inherit',
                    fontWeight: formData.industryType.includes(industry.id.toString())
                      ? 'bold'
                      : 'normal',
                  }}
                >
                  {industry.definition}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntakeFormIndustryType;
