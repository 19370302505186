import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { CommonTypography } from '../../../../styles/Global.styles';
import ReactSpeedometer from 'react-d3-speedometer';
import { max } from 'moment';

const data = [
  { name: 'Needs Attention', value: 52, color: '#E43E3E' },
  { name: 'Could Improve', value: 28, color: '#FFFF00' },
  { name: 'Meeting Needs', value: 20, color: '#00B050' },
];

const styles = {
  dial: {
    display: 'flex', // changed from inline-block to flex
    justifyContent: 'center', // center horizontally
    alignItems: 'center',
    width: `300px`,
    height: `auto`,
    color: '#000',
    border: '0.5px solid #fff',
    padding: '2px',
    margin: '-10px',
  },
  title: {
    fontSize: '1em',
    color: '#000',
  },
};

export default function GaugeChart({ name, score, maxScore = 5 }) {  // Set default value for maxScore
  let scoreText = String(score);
  if (scoreText == 'undefined') {
    scoreText = 'No Scores Received';
  }

  return (
    <Paper
      sx={{
        height: '270px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        margin="10px 20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CommonTypography sx={{ fontSize: '19px', fontWeight: 500 }}>
          {name}
        </CommonTypography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <div
          style={styles.dial}
        >
          <ReactSpeedometer
            maxValue={maxScore}  // Use maxScore prop here
            value={score}
            height={190}
            width={290}
            needleTransitionDuration={1000}
            needleColor="black"
            needleHeightRatio={0.7}
            startColor="" // Not used but required prop
            segments={5}
            endColor="" // Not used but required prop
            customSegmentStops={[
              0, 
              maxScore * 0.30, 
              maxScore * 0.47, 
              maxScore * 0.53, 
              maxScore * 0.70, 
              maxScore]}  // Adjust stops based on maxScore
            segmentColors={['#E43E3E', '#FFC000', '#FFFF00', '#92D050', '#00B050' ]} // Red, Orange, Yellow, Light-Green, Green
            currentValueText={`${scoreText}`}
            customSegmentLabels={[
              {
                text: "", // Empty string for no label for each section
              },
              {
                text: "",
              },
              {
                text: "",
              },
              {
                text: "",
              },
              {
                text: "",
              }
            ]}
          />
        </div>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {data.map((item, i) => (
          <React.Fragment key={i}>
            <Box
              sx={{
                display: 'flex',
                maxWidth: '30%',
                margin: '0 10px',
              }}
            >
              <Box
                sx={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: item.color,
                  float: 'left',
                  marginRight: '5px',
                  position: 'relative',
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                {item.name}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Paper>
  );
}
