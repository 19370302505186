import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { AssessmentQuestion } from '../../../Assessments/type';
import { SingleQuestionResponse } from '../../type';

interface NumericEntryInputProps {
  question: AssessmentQuestion;
  formData: SingleQuestionResponse[];
  onChange: (questionId: number, selectedAnswerId?: number, answerText?: string) => void;
}

export default function NumericEntryInput({
  question,
  formData,
  onChange,
}: NumericEntryInputProps) {
  const currentAnswer = formData.find(answer => answer.assessment_question_id === question.id);

  // Handle change using the centralized onChange method from the parent
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // Use a regex to allow only numbers and decimals
    const value = ev.target.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      // Only trigger onChange if the input is valid (numbers and decimals only)
      onChange(question.id, undefined, value);
    }
  };

  return (
    <FormControl sx={{ marginTop: '20px', marginLeft: '40px' }}>
      <TextField
        id={`numeric-field-${question.id}`}
        variant="standard"
        sx={{
          width: '10em',
        }}
        placeholder="Unanswered"
        value={currentAnswer?.answer || ''}
        onChange={handleChange}
        inputProps={{
          inputMode: 'decimal', // Allows numeric and decimal input
          pattern: '-?[0-9]*\\.?[0-9]*', // Restricts characters to numbers and decimal
        }}
      />
    </FormControl>
  );
}
