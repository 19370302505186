import { useEffect, useState } from 'react';
import { CustomAccountBranding } from '../type';
import { Tenant } from '../../../config/axios';
import translation from '../../../locales/en/translation.json';

export const useGetBranding = () => {
  const [branding, setBranding] = useState<CustomAccountBranding | null>(null);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const getBranding = () => {
      try {
        if (Tenant && !branding) {
          const response: CustomAccountBranding =
          {
            company_name: Tenant.company_name,
            evaluation_survey_description: translation.fallback_evaluation_survey_para,
            company_logo_primary: Tenant.company_logo_primary,//(Tenant.domain as string) + 'media/' + (Tenant.company_logo_primary as string),
            company_logo_secondary: Tenant.company_logo_secondary, //(Tenant.domain as string) + 'media/' + (Tenant.company_logo_secondary as string),
            favicon: Tenant.favicon,//(Tenant.domain as string) + 'media/' + (Tenant.favicon as string), //Need this to work with png
            primary_color: Tenant.primary_color,
            secondary_color: Tenant.secondary_color,
            browser_tab_title: Tenant.browser_tab_title,
            evaluation_property_name_translation: Tenant.evaluation_property_name_translation,
            login_headline: Tenant.login_headline,
            login_paragraph: Tenant.login_paragraph,
            assessor_score: Tenant.assessor_score,
            active_feature_flags: Tenant.active_feature_flags
          }

          setBranding(response);
          if (intervalId)
            clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Failed to GET branding:', error);
      }
    };

    intervalId = setInterval(getBranding, 1000); // Adjust the interval as needed    

  }, [branding]);

  return branding;
};