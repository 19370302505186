import React from 'react';

import { Modal, Button, Typography } from '@mui/material';
import { AccountDetails } from '../../modules/Accounts/types';
import { CustomAccountBranding } from '../../modules/Branding/type';

const IntakeFormExistingCompanyModal = ({
  open,
  onClose,
  onAssessmentCreationContinue,
  onExistingAssessmentContinue,
  existingCompany,
  branding,
  existingAssessmentGroup,
}: {
  open: boolean;
  onClose: () => void;
  onAssessmentCreationContinue: () => void;
  onExistingAssessmentContinue: () => void;
  existingCompany: AccountDetails | null;
  branding: CustomAccountBranding;
  existingAssessmentGroup: boolean;
}) => (
  <Modal open={open} onClose={onClose}>
    {existingAssessmentGroup ? (
      <div
        style={{
          padding: '2%',
          backgroundColor: 'white',
          margin: '5% auto',
          width: '35%',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h4">
          {branding.evaluation_property_name_translation} found
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '4%' }}>
          {existingCompany?.name} is already registered and has Employer of Choice{' '}
          {branding.evaluation_property_name_translation.toLowerCase()}.{' '}
        </Typography>
        <div
          style={{
            marginTop: '8%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onExistingAssessmentContinue}>
            Take {branding.evaluation_property_name_translation.toLowerCase()}
          </Button>
        </div>
      </div>
    ) : (
      <div
        style={{
          padding: '2%',
          backgroundColor: 'white',
          margin: '5% auto',
          width: '35%',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h4">
          {branding.evaluation_property_name_translation} not found
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '4%' }}>
          {existingCompany?.name} has not taken the Employer of Choice {branding.evaluation_property_name_translation.toLowerCase()} this year
        </Typography>
        <div
          style={{
            marginTop: '8%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onAssessmentCreationContinue}>
            Create {branding.evaluation_property_name_translation.toLowerCase()}
          </Button>
        </div>
      </div>
    )}
  </Modal>
);

export default IntakeFormExistingCompanyModal;
