import React, { useState } from 'react';
import { Box, Typography, Container, CircularProgress, Grid } from '@mui/material';
import AssessmentNavBar from '../../../components/NavBar/AssessmentNavBar';
import { CustomAccountBranding } from '../../Branding/type';
import { SaveButton } from '../../Accounts/CreateClient/CreateClient.styles';
import AssessmentForm from './AssessmentForm';
import CompletionConfirmationTab from './CompletionConfirmationTab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';
import useGetPublicAssessmentDetails from '../../Assessments/hooks/useGetPublicAssessmentDetails';
import { PublicAssessmentOverviewDetails } from '../../Assessments/type';

interface AssessmentFormTabsProps {
    branding: CustomAccountBranding;
    id: string;
}

const AssessmentFormTabs: React.FC<AssessmentFormTabsProps> = ({ id, branding }) => {
    const [currentTab, setCurrentTab] = useState(0);  // Manage current tab state    
    const { getPublicDetails, error, loading } = useGetPublicAssessmentDetails();
    const location = useLocation();
    const [childSurveyLink, setChildSurveyLink] = useState<string | null>(null);


    // const handleSubmit = () => {
    //     setCurrentTab(2);  // After submitting the assessment, go to Completion Confirmation tab
    // };

    const assessmentDetail: PublicAssessmentOverviewDetails | null = useAppSelector((state) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return state.assessmentReducers.getPublicAssessmentDetailsReducer?.assessment_details || null;
    });

    React.useEffect(() => {
        if (id) {
            getPublicDetails(id);
        }
    }, [id]);

    React.useEffect(() => {
        if (
            assessmentDetail?.is_child_assessment === false &&
            assessmentDetail?.child_assessment_id
        ) {
            const baseUrl = `${window.location.origin}${location.pathname.replace(/\/[^/]+$/, '')}`;
            setChildSurveyLink(`${baseUrl}/${assessmentDetail.child_assessment_id}`);
        }
    }, [assessmentDetail, location.pathname]);

    if (!error && !assessmentDetail) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Grid container sx={{ backgroundColor: 'white', width: '100%', fontFamily: 'Inter', }}>
            {/* Navigation Bar with Tabs */}
            <AssessmentNavBar branding={branding} surveyName={assessmentDetail?.assessment_definition.assessment_type.name || "Survey"} currentTab={currentTab} setCurrentTab={setCurrentTab} />

            {/* Loading State */}
            {(!assessmentDetail && loading) && <CircularProgress sx={{ margin: '20vh auto' }} />}

            {/* Display error message */}
            {error && (
                <Typography
                    variant="h6"
                    sx={{
                        margin: '40px auto',
                        color: '#9095a1',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        textAlign: 'center'
                    }}
                >
                    {`Unable to load form. ${error}.` || 'Unable to load form.'}
                </Typography>
            )}

            {/* Tab Content */}
            {(assessmentDetail && !loading && !error) && (
                <Grid container>
                    {/* Introduction Tab */}
                    {currentTab === 0 && (
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                padding: '8%',
                                borderRadius: '8px',
                                marginTop: '2%'
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: '20px',
                                    color: `${branding.primary_color || '#053D58'}`,
                                    fontFamily: "Inter",
                                    fontSize: "28px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                }}
                            >
                                {assessmentDetail?.name}
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: '20px',
                                    color: "#6A6A6A",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                }}
                            >
                                {assessmentDetail?.assessment_definition.assessment_version.summary || branding?.evaluation_survey_description}
                            </Typography>
                            <SaveButton
                                onClick={() => setCurrentTab(1)}
                                sx={{
                                    position: 'absolute',  // Position it absolutely within the Box
                                    bottom: '20%',        // 20px from the bottom
                                    right: '15%',

                                    padding: "7px 15px",

                                    color: "#FFF",

                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                }}
                            >
                                Continue
                                <ArrowForwardIcon sx={{ marginLeft: '8px' }} />
                            </SaveButton>
                        </Box>
                    )}

                    {/* Supply Chain Assessment Tab */}
                    {currentTab === 1 && (
                        <AssessmentForm assessmentDetail={assessmentDetail} branding={branding} setParentTab={setCurrentTab} />
                    )}

                    {/* Completion Confirmation Tab */}
                    {currentTab === 2 && (
                        <CompletionConfirmationTab childSurveyLink={childSurveyLink} branding={branding} />
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default AssessmentFormTabs;
