import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { AccountSettingsUser } from './AccountSettings';
import CloseIcon from '../../../assets/svg-components/CloseIcon';
import {
  MobileIcon,
  EnvelopeIcon,
} from '../../../assets/svg-components/SvgIcons';
import {
  Typography12px400,
  Typography18px500,
} from '../../../styles/Global.styles';
import translation from '../../../locales/en/translation.json';
import { UserCarBox } from './UserCard.style';

type CardProps = {
  user: AccountSettingsUser;
  removeUser: (id: string) => void;
};

const UserCard: React.FC<CardProps> = (props) => {
  const { user } = props;
  const passUserId = () => {
    props.removeUser(`${user.id}`);
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      sx={{
        margin: '10px 0',
      }}
    >
      <UserCarBox
        sx={{
          position: 'relative',
          background:
            user.role === 'account_executive'
              ? 'rgba(18, 40, 242, 0.05)'
              : 'rgba(0, 219, 153, 0.05)',
        }}
      >
        <Box
          component={'span'}
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            top: '10px',
            right: '10px',
          }}
          onClick={passUserId}
        >
          <CloseIcon />
        </Box>

        <Grid
          container
          sx={{
            display: 'flex',
            gap: '25px',
            alignItems: 'center',
          }}
        >
          <Grid item>
            <Avatar
              sx={{
                height: '50px',
                width: '50px',
              }}
            >
              {user.fullname ? user.fullname[0]: ""}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography18px500
              sx={{
                color: '#272727',
                paddingBottom: '5px',
              }}
            >
              {user.fullname}
            </Typography18px500>
            <Typography12px400
              sx={{
                color: '#717986',
              }}
            >
              {user.role === 'account_executive'
                ? translation.account_executive
                : translation.support}
            </Typography12px400>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                gap: '25px',
                alignItems: 'center',
                paddingLeft: '10px',
                margin: '10px 0',
              }}
            >
              <Box>
                <Button
                  sx={{
                    borderRadius: 'var(--corner-radius-8, 8px)',
                    minWidth: '0',
                    height: '28px',
                    width: '28px',
                    background: 'var(--black-5, rgba(0, 0, 0, 0.05))',
                  }}
                >
                  <EnvelopeIcon></EnvelopeIcon>
                </Button>
              </Box>
              <Box>
                <Typography12px400
                  sx={{
                    color: '#1228F2',
                    textDecorationLine: 'underline',
                  }}
                >
                  {user.email}
                </Typography12px400>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </UserCarBox>
    </Grid>
  );
};

export default UserCard;